<template>
	<div class="vmodal vmodal--img">
		<button class="vmodal__close" @click="$emit('close')">
			<v-icon name="times"></v-icon>
		</button>

		<div class="vmodal__container">
			<div class="vmodal__main">
				<div class="img">
					<div class="img__cnt">
						<img :src="card.img" alt="">
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'modal-img',
		props: ['card']
	};
</script>

<style scoped lang=scss>
	.img{
		padding: 15px;
		border-radius: 10px;
		background: #ffffff;
		&__cnt{
			border-radius: 10px;
			overflow: hidden;
			line-height: 0;
		}
	}
	.vmodal{
		background: none;
		&__main{
			text-align: center;
			padding-top: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		&__close{
			svg{
				fill: #fff;
				width: 40px;
				height: 40px;
			}

		}
	}
</style>
