<template>
	<div class="card" :class="{'has-end': hasCardInTable}" @click="onRunEvent">
		<div class="card__bg"  :style="{'background-image': `url(${card.img}`}"></div>
		<div class="card__back" :style="{'background-image': `url(${require('@img/pattern.png')}`}" :class="{'has-active': hasOpenBack}">
			<img :src="require('@img/logo.png')" alt="">
		</div>
		<div class="card__options">
			<div class="card__option" @click.stop="onShowImg">
				<button class="card__icon">
					<v-icon name="eye"></v-icon>
				</button>
			</div>
			<div class="card__option" v-if="type === CARD_TYPE_HAND">
				<button class="card__icon" @click.stop="onDeleteCard">
					<v-icon name="trash-alt"></v-icon>
				</button>
			</div>
		</div>

		<div class="card__end" :style="{'background-image': `url(${require('@img/card_get_border.png')})`}">
			<span>Взято</span>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
	import ModalImg from '@common/components/ModalImg';

	export default {
		name: 'board-card',
		props: ['card', 'type', 'hasOpenBack'],
		computed:{
			...mapState({
				CARD_TYPE_PACK: state => state.board.CARD_TYPE_PACK,
				CARD_TYPE_TABLE: state => state.board.CARD_TYPE_TABLE,
				CARD_TYPE_HAND: state => state.board.CARD_TYPE_HAND,

				tableList: state => state.board.tableList,
				handList: state => state.board.handList
			}),

			hasCardInTable(){
				let getList = this.tableList.concat(this.handList);

				return $fn.findIndex(getList, ['id', this.card.id]) !== -1 && this.type === this.CARD_TYPE_PACK;
			}
		},

		methods:{
			...mapMutations({
				ADD_CARD: 'board/ADD_CARD',
				DELETE_CARD: 'board/DELETE_CARD'
			}),

			onRunEvent(){
				if(this.hasCardInTable) return;

				if(this.type === this.CARD_TYPE_PACK) this.onAddToHand();
				if(this.type === this.CARD_TYPE_HAND) this.onAddToTable();
			},

			onAddToHand(){
				this.ADD_CARD({
					type: this.CARD_TYPE_HAND,
					card: this.card
				})
			},

			onAddToTable(){
				this.ADD_CARD({
					type: this.CARD_TYPE_TABLE,
					card: this.card
				});

				this.$bus.$emit('event-draw-card', this.card);
			},

			onDeleteCard(){
				this.DELETE_CARD({
					type: this.type,
					cardId: this.card.id
				})
			},

			onShowImg(){
				this.$modal.show(ModalImg,{
					card: this.card
				}, $MODAL_OPTIONS.full);
			}
		}
	};
</script>

<style scoped lang=scss>
	.card{
		width: 100%;
		height: 100%;
		background: greenyellow;
		border-radius: 10px;
		overflow: hidden;
		position: relative;
		transition: all 0.3s;
		cursor: pointer;
		user-select: none;
		&:hover{
			/*box-shadow: 0 2px 8px rgba(0,0,0,0.4);*/
			box-shadow: 0 2px 15px $c_alt;
			transform: scale(1.02);
			.card__navs,
			.card__options{
				opacity: 1;
				visibility: visible;
			}

		}
		&.has-end{
			cursor: initial;
			box-shadow: none;
			transform: scale(1);
			.card__navs{
				opacity: 0;
				visibility: hidden;
			}
			.card__end{
				opacity: 1;
				visibility: visible;
			}
			.card__bg{
				filter: grayscale(1);
			}
			.card__back{
				opacity: 0.5;
			}
		}
		&__bg{
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			transition: all 0.3s;
		}
		&__options{
			position: absolute;
			z-index: 1;
			padding: 10px;
			display: flex;
			justify-content: flex-end;
			width: 100%;
			top: 0;
			opacity: 0;
			visibility: hidden;
			transition: all 0.3s;
		}
		&__navs{
			position: absolute;
			bottom: 0;
			width: 100%;
			display: flex;
			justify-content: center;
			padding-bottom: 10px;
			opacity: 0;
			visibility: hidden;
			transition: all 0.3s;
		}
		&__icon{
			width: 30px;
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: none;
			background: none;
			cursor: pointer;
			&:hover{
				svg{
					fill: $c_alt;
				}
			}
			svg{
				fill: #fff;
				width: 25px;
				height: 25px;
			}
		}
		&__btn{
			font-size: 14px;
			border-radius: 5px;
			border: none;
			background: rgba(255,255,255,0.9);
			padding: 7px 15px;
			font-family: $f_alt;
			display: flex;
			align-items: center;
			cursor: pointer;
			&:hover{
				box-shadow: 0 2px 8px rgba(0,0,0,0.4);
				span{
					color: $c_alt;
				}
				svg{
					fill: $c_alt;
				}
			}
			span{
				transform: translateY(2px);
				transition: all 0.3s;
				font-size: 16px;
			}
		}

		&__end{
			position: absolute;
			z-index: 1;
			width: 195px;
			height: 122px;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: contain;
			display: flex;
			align-items: center;
			justify-content: center;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			opacity: 0;
			visibility: hidden;
			transition: all 0.3s;
			span{
				font-family: $f_alt;
				font-size: 32px;
				color: $c_alt;
				display: block;
				transform: translate(2px, 2px) rotate(-21deg);;
			}
		}
		&__back{
			position: absolute;
			z-index: 1;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			top: 0;
			left: 0;
			background-color: #fff;
			padding: 20px;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			opacity: 0;
			visibility: hidden;
			transition: all 0.3s;
			&.has-active{
				opacity: 1;
				visibility: visible;
			}
		}
	}
</style>
