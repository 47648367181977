<template>
	<div class="bot" v-if="viewHandList.length">
		<div class="bot__wrap">
			<ul class="bot__list">
				<li class="bot__item" v-for="card in viewHandList">
					<div class="bot__card">
						<board-card :card="card" :type="CARD_TYPE_HAND"></board-card>
					</div>
				</li>
			</ul>
		</div>

		<div class="bot__dim"></div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
	import BoardCard from '@views/board/BoardCard';

	export default {
		name: 'drow-hand',
		components:{
			BoardCard
		},

		computed:{
			...mapState({
				CARD_TYPE_HAND: state => state.board.CARD_TYPE_HAND,
				handList: state => state.board.handList,
				tableList: state => state.board.tableList
			}),

			viewHandList(){
				return $fn.difference(this.handList, this.tableList);
			}
		}
	};
</script>

<style scoped lang=scss>
	.bot{
		height: 100%;
		width: 100%;
		&__wrap{
			height: 100%;
			width: 100%;
			opacity: 0.5;
			transition: all 0.3s;
			position: relative;
			z-index: 1;
			&:hover{
				opacity: 1;
				.bot__list{
					transform: translateY(-270px);
				}
				.bot__card{
					filter: grayscale(0);
				}
				+ .bot__dim{
					opacity: 1;
					visibility: visible;
				}
			}
		}
		&__list{
			display: flex;
			transition: all 0.3s;
			overflow: auto;
			padding: 10px 0;
			&::-webkit-scrollbar {
				height: 12px;
				background-color: #F5F5F5;
				border-radius: 5px;
				cursor: pointer;
			}
			&::-webkit-scrollbar-track {
				-webkit-box-shadow: inset 0 0 6px #867197;
				border-radius: 5px;
				background-color: #F5F5F5;
			}
			&::-webkit-scrollbar-thumb {
				border-radius: 5px;
				-webkit-box-shadow: inset 0 0 6px #867197;
				background-color: #867197;
			}
		}
		&__item{
			margin: 0 10px;
		}
		&__card{
			width: 250px;
			height: 350px;
			filter: grayscale(1);
		}
		&__dim{
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(255,255,255,0.5);
			opacity: 0;
			visibility: hidden;
			transition: all 0.3s;
		}
	}
</style>
