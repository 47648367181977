<template>
	<svg id="Capa_1" enable-background="new 0 0 482.239 482.239" height="512" viewBox="0 0 482.239 482.239" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m465.016 0h-447.793c-9.52 0-17.223 7.703-17.223 17.223v447.793c0 9.52 7.703 17.223 17.223 17.223h447.793c9.52 0 17.223-7.703 17.223-17.223v-447.793c0-9.52-7.703-17.223-17.223-17.223zm-17.223 447.793h-413.347v-413.347h413.348v413.347z"/></svg>
</template>

<script>
	export default {
		name: 'icon-square'
	};
</script>

<style scoped lang=scss>

</style>
