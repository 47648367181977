<template>
	<div class="board" :style="{'background-image': `url(${require('@img/draw_bg.webp')}`}">
		<div class="board__wrap">
			<div class="board__main">
				<draw-table :space="space"></draw-table>
			</div>

			<div class="board__bot">
				<draw-hand v-if="hasStartWork"></draw-hand>
				<div class="board__empty" v-else>Щоб викласти карти на стiл, спочатку вiдкрийте колоду та вiзьмiть карти в руку :)</div>
			</div>
		</div>

		<div class="board__sub" :class="{'has-active': hasOpenPack}">
			<div class="board__head">
				<div class="board__nav" @click="hasOpenBack = !hasOpenBack" :class="{'has-active': hasOpenBack}">
					<font-awesome-icon icon="fa-regular fa-card-spade" />
					<span>С рубашкой</span>
				</div>

				<div class="board__nav" @click="onRefresh" :class="{'has-refresh': hasRefresh}">
					<font-awesome-icon icon="fa-regular fa-arrows-rotate" />
					<span>Перемешать</span>
				</div>

				<div class="board__nav" @click="hasOpenPack = !hasOpenPack">
					<font-awesome-icon v-if="hasOpenPack" icon="fa-regular fa-arrow-up" />
					<font-awesome-icon v-else icon="fa-regular fa-arrow-down" />
					<span>{{hasOpenPack ? 'Закрити колоду' : 'Вiдкрити колоду'}}</span>
				</div>
			</div>

			<div class="board__cnt" v-if="hasLoadPack">
				<draw-pack :hasOpenBack="hasOpenBack"></draw-pack>
			</div>
		</div>

		<div class="board__dim" :class="{'has-active': hasOpenPack}" @click="hasOpenPack = false"></div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';

	import spaces from '@/database/spaces.js';
	import DrawTable from './draw/DrawTable';
	import DrawHand from './draw/DrawHand';
	import DrawPack from './draw/DrawPack';

	export default {
		name: 'board-index',
		components:{
			DrawTable,
			DrawHand,
			DrawPack
		},
		props: ['id'],
		data(){
			return{
				spaces: spaces,

				hasOpenBack: false,
				hasRefresh: false,
				hasOpenPack: false,

				hasLoadPack: false
			}
		},

		computed:{
			...mapState({
				hasStartWork: state => state.board.hasStartWork,
				handList: state => state.board.handList,
			}),

			space(){
				return $fn.find(this.spaces, ['id', +this.id])
			}
		},

		created(){
			this.getPacks();
		},

		methods:{
			...mapActions({
				GET_PACKS: 'board/GET_PACKS'
			}),

			getPacks(){
				this.GET_PACKS().then(() => {
					this.hasLoadPack = true;
				});
			},

			onRefresh(){
				this.hasRefresh = true;
				this.$bus.$emit('event-refresh');

				setTimeout(() => {
					this.hasRefresh = false
				}, 2000)
			}
		}
	};
</script>

<style scoped lang=scss>
	.board{
		font-size: 40px;
		height: 100%;
		display: flex;
		padding: 10px;
		overflow: hidden;
		width: 100%;
		position: relative;
		background-position: center top;
		background-repeat: no-repeat;
		background-size: cover;
		&__wrap{
			height: 100%;
			width: 100%;
			padding-bottom: 120px;
		}
		&__main{
			padding: 10px;
			height: 100%;
		}
		&__bot{
			padding: 10px;
			height: 120px;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
		}
		&__sub{
			padding: 0px 10px 10px;
			border-radius: 0 0 10px 10px;
			position: fixed;
			z-index: 2;
			top: -390px;
			height: 390px;
			left: 100px;
			right: 100px;
			background: #DCD2E4;
			transition: all 0.3s;
			&.has-active{
				top: 0;
			}
		}
		&__head{
			position: absolute;
			top: 100%;
			right: 20px;
			transform: translate(0, 0);
			background: #DCD2E4;
			cursor: pointer;
			padding: 20px 20px;
			border-radius: 0 0 10px 10px;
			display: flex;
			justify-content: center;
			align-items: center;

		}
		&__nav{
			font-size: 14px;
			padding: 0 10px;
			color: $c_text_light;
			&:hover{
				color: $c_alt;
			}
			&.has-active{
				color: $c_success;
			}
			&.has-refresh{
				color: $c_success;
				pointer-events: none;
				user-select: none;
				opacity: 0.2;
			}
			span{
				font-size: 16px;
				line-height: 1.2em;
				text-align: center;
				font-family: $f_alt;
				transition: all 0.3s;
				margin-left: 5px;
			}
		}
		&__dim{
			background: rgba(0,0,0,0.7);
			position: fixed;
			z-index: 1;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			visibility: hidden;
			transition: all 0.3s;
			&.has-active{
				opacity: 1;
				visibility: visible;
			}
		}
		&__empty{
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 20px;
			text-align: center;
			font-family: $f_alt;
			color: #867197;
			font-size: 22px;
		}
	}
</style>
