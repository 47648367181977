<template>
	<div class="sub">
		<div class="sub__main">
			<ul class="sub__list">
				<li class="sub__item" v-for="card in selectedPackList">
					<div class="sub__card">
						<board-card :card="card" :type="CARD_TYPE_PACK" :hasOpenBack="hasOpenBack"></board-card>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
	import BoardCard from '@views/board/BoardCard';

	import Slick from 'vue-slick';
	import 'slick-carousel/slick/slick.css';

	export default {
		name: 'draw-pack',
		components:{
			Slick,
			BoardCard
		},
		props: ['hasOpenBack'],
		data(){
			return{
				slickOptions: {
					slidesToShow: 4,
					centerMode: true
				},

				selectedPackList: []
			}
		},

		computed:{
			...mapState({
				CARD_TYPE_PACK: state => state.board.CARD_TYPE_PACK,
				packList: state => state.board.packList
			})
		},

		created(){
			this.selectedPackList = this.shuffleArray(this.packList);

			this.$bus.$on('event-refresh', () => {
				this.shuffleArray(this.packList);
			});
		},

		methods:{
			shuffleArray(array){
				return  array.sort(() => Math.random() - 0.5);
			}
		}
	};
</script>

<style>
	.slick-arrow{
		display: none !important;
	}
</style>

<style scoped lang=scss>
	.sub{
		width: 100%;
		height: 100%;
		position: relative;
		&:hover{
			.sub__arr{
				opacity: 1;
				visibility: visible;
			}
		}
		&__main{
			width: 100%;
			overflow: auto;
			&::-webkit-scrollbar {
				height: 12px;
				background-color: #F5F5F5;
				border-radius: 5px;
				cursor: pointer;
			}
			&::-webkit-scrollbar-track {
				-webkit-box-shadow: inset 0 0 6px #867197;
				border-radius: 5px;
				background-color: #F5F5F5;
			}
			&::-webkit-scrollbar-thumb {
				border-radius: 5px;
				-webkit-box-shadow: inset 0 0 6px #867197;
				background-color: #867197;
			}
		}
		&__list{
			display: flex;
			width: 109%;
			padding: 10px 0;
		}
		&__item{
			padding: 0 10px;
			display: flex !important;
			justify-content: center;
		}
		&__card{
			width: 250px;
			height: 350px;
		}
		&__arr{
			position: absolute;
			z-index: 1;
			top: 0;
			width: 200px;
			height: 100%;
			background: rgba(0,0,0,0.7);
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			user-select: none;
			opacity: 0;
			visibility: hidden;
			transition: all 0.3s;
			&:hover{
				background: rgba(0,0,0,0.7);
				span{
					color: $c_alt;
				}
				svg{
					fill: $c_alt;
				}
			}
			&--left{
				left: 0;
				border-radius: 0 30px 30px 0;
			}
			&--right{
				right: 0;
				border-radius: 30px 0 0 30px;
			}
			span{
				color: #fff;
				font-size: 16px;
				font-family: $f_alt;
				margin-bottom: 5px;
				transition: all 0.3s;
			}
			svg{
				fill: #fff;
			}
		}
	}
</style>
