<template>
	<div class="draw" ref="editorWrap">
		<div class="menu" :class="{'has-active': hasOpenMenu}">
			<div class="menu__head" @click="hasOpenMenu = !hasOpenMenu">
				<v-icon v-if="!hasOpenMenu" name="bars"></v-icon>
				<v-icon v-else name="times"></v-icon>
			</div>

			<div class="menu__cnt">
				<ul class="menu__list">
					<li class="menu__item">
						<div class="menu__fileImg">
							<input type="file" @change="onLoadImg">
							<icon-images></icon-images>
						</div>
					</li>
					<li class="menu__item">
						<button class="menu__btn menu__btn--light" @click="onAddText">
							<icon-font></icon-font>
						</button>
					</li>
					<li class="menu__item">
						<button class="menu__btn menu__btn--light" @click="onAddFigureSquare">
							<icon-square></icon-square>
						</button>
					</li>
					<li class="menu__item">
						<button class="menu__btn menu__btn--light" @click="onAddFigureCircle">
							<icon-circle></icon-circle>
						</button>
					</li>
					<li class="menu__item">
						<button class="menu__btn menu__btn--light" @click="onAddFigureTriangle">
							<icon-triangle></icon-triangle>
						</button>
					</li>
				</ul>
			</div>
		</div>

		<div class="draw__navs">
			<div class="draw__nav">

			</div>
		</div>

		<div class="draw__area">
			<canvas :width="`${selectedLayout.width}px`" :height="`${selectedLayout.height}px`"  ref='editor' id="editor">Оновіть браузер</canvas>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
	import { fabric } from 'fabric';

	import IconImages from '@common/icons/IconImages';
	import IconFont from '@common/icons/IconFont';
	import IconSquare from '@common/icons/IconSquare';
	import IconCircle from '@common/icons/IconCircle';
	import IconTriangle from '@common/icons/IconTriangle';

	export default {
		name: 'draw-table',
		components:{
			IconImages,
			IconFont,
			IconSquare,
			IconCircle,
			IconTriangle
		},
		props:['space'],

		data(){
			return{
				TYPE_OBJ_SPACE: 'space',
				TYPE_OBJ_CARD: 'card',

				canvas: null,

				canvasWrapDOM: null,
				canvasDOM: null,

				counterId: 1,
				canvasObjList: [],
				selectedObjId: null,

				color: '#DCD2E4',

				selectedLayout:{
					width: null,
					height: null
				},

				hasOpenMenu: false,

				textSize: '28',
				textFamily: 'Arial',

				textSizeMap: [12,14,16,18,19,20,24, 28, 32, 48],
				textFamilyMap: ['Arial', 'Verdana', 'Times New Roman', 'Georgia', 'Courier New', 'Lucida Console']
			}
		},

		computed:{
			...mapState({
				CARD_TYPE_TABLE: state => state.board.CARD_TYPE_TABLE,
			}),

			selectedColor(){
				return this.color
			}
		},

		mounted(){
			this.setDefaultData();
			setTimeout(() => {
				this.initCanvas();
			}, 1000);

			document.onkeydown = (e) => {
				if (46 === e.keyCode) {
					this.eventDeleteObj();
				}
			};
			
			this.$bus.$on('event-draw-card', card => {
				this.onLoadCard(card);
			})
		},

		methods:{
			...mapMutations({
				DELETE_CARD: 'board/DELETE_CARD'
			}),

			initCanvas(){
				this.canvas = new fabric.Canvas('editor',{
					selection: false,
					width: this.selectedLayout.width,
					height: this.selectedLayout.height
				});

				this.canvas.on('mouse:down', options => {
					if (options.target){
						this.selectedObjId = options.target.id;

					} else {
						this.selectedObjId = null
					}
				});
			},

			draw(){
				this.canvas.clear();

				$fn.forIn(this.canvasObjList, item => {
					if(item.visible) this.canvas.add(item);
				});
			},

			eventDeleteObj(){
				if(!this.selectedObjId) return;

				let selectIndex = $fn.findIndex(this.canvasObjList, ['id', this.selectedObjId]);
				let idKey = this.canvasObjList[selectIndex].id;

				let type = idKey.split('_')[0],
					idNum = +idKey.split('_')[1];

				if(type === this.TYPE_OBJ_CARD){
					this.DELETE_CARD({
						type: this.CARD_TYPE_TABLE,
						cardId: idNum
					})
				}

				this.canvasObjList.splice(selectIndex, 1);

				this.draw();
			},

			setDefaultData(){
				this.canvasWrapDOM = this.$refs['editorWrap'];

				this.selectedLayout.width = this.canvasWrapDOM.offsetWidth;
				this.selectedLayout.height = this.canvasWrapDOM.offsetHeight;

				this.canvasDOM = document.getElementById('editor');
			},

			async onLoadImg(e){
				let file =  e.target.files[0],
					image_64 = null;

				await this.getBase64(file).then(img => {
					image_64 = img
				});

				this.onAddImg(image_64)
			},

			onLoadCard(card){
				let image = new Image();
				image.crossOrigin = 'Anonymous';
				image.src = card.img;

				image.onload = () => {
					const canvas = document.createElement('canvas');
					const ctx = canvas.getContext('2d');

					canvas.width = image.width;
					canvas.height = image.height;

					ctx.drawImage(image, 0, 0);
					let img64 =  canvas.toDataURL('image/jpeg');

					this.onAddImg(img64, this.TYPE_OBJ_CARD, card);
				};
			},

			onAddText(){
				let item = new fabric.IText(
					'Введіть текст',
					{
						id: `${this.TYPE_OBJ_SPACE}_${this.counterId++}`,
						visible: true,
						label: 'Текст',
						left: 100,
						top: 100,
						fontFamily: this.textFamily,
						fill:  '#4A3A5F',
						fontSize: this.textSize,
						lineHeight: 1.1,
					});

				this.canvasObjList.push(item);
				this.draw();
			},

			onAddFigureSquare(){
				let item = new fabric.Rect({
					id: `${this.TYPE_OBJ_SPACE}_${this.counterId++}`,
					visible: true,
					label: 'Прямокутник',
					left: 130,
					top: 100,
					fill: this.selectedColor,
					width: 50,
					height: 50,
					angle: 45,
				});

				this.canvasObjList.push(item);
				this.draw();
			},

			onAddFigureCircle(){
				let item = new fabric.Circle({
					id: `${this.TYPE_OBJ_SPACE}_${this.counterId++}`,
					visible: true,
					label: 'Коло',
					left: 200,
					top: 200,
					radius: 30,
					fill: this.selectedColor
				});

				this.canvasObjList.push(item);
				this.draw();
			},

			onAddFigureTriangle(){
				let item = new fabric.Triangle({
					id: `${this.TYPE_OBJ_SPACE}_${this.counterId++}`,
					visible: true,
					label: 'Трикутник',
					width: 40,
					height: 50,
					fill: this.selectedColor,
					left: 150,
					top: 150
				});

				this.canvasObjList.push(item);
				this.draw();
			},

			onAddImg(image64, type, card){

				fabric.Image.fromURL(image64, (oImg) => {
					oImg.id = (type === this.TYPE_OBJ_CARD) ? `${this.TYPE_OBJ_CARD}_${card.id}` : `${this.TYPE_OBJ_SPACE}_${this.counterId++}`;
					oImg.visible = true;
					oImg.label = 'Малюнок';

					this.canvasObjList.push(oImg);

					this.draw();
				});
			},

			getBase64(file){
				if(!file) return;

				let reader = new FileReader();
				reader.readAsDataURL(file);

				return new Promise((resolve, reject) => {
					reader.onload = function () {
						return resolve(reader.result)
					};
					reader.onerror = function (error) {
						return reject(error)
					};
				})
			}
		}
	};
</script>

<style scoped lang=scss>
	.draw{
		background: rgba(255,255,255,0.4);
		border-radius: 5px;
		width: 100%;
		height: 100%;
		position: relative;
		&__area{
			height: 100%;
		}
	}

	.menu{
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		&.has-active{
			.menu__cnt{
				top: 0;
				opacity: 1;
				visibility: visible;
			}
		}
		&__head{
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			line-height: 0;
			text-align: center;
			&:hover{
				svg{
					fill: $c_alt;
				}
			}
			svg{
				width: 20px;
				height: 20px;
			}
		}
		&__cnt{
			position: relative;
			opacity: 0;
			top: 30px;
			visibility: hidden;
			transition: all 0.3s;
		}
		&__list{
			display: flex;
			flex-direction: column;
		}
		&__btn{
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: transparent;
			border: none;
			cursor: pointer;
			&:hover{
				svg{
					fill:$c_alt;
				}
			}
			svg{
				width: 22px;
				height: 22px;
			}
		}
		&__fileImg{
			position: relative;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			&:hover{
				svg{
					fill: $c_alt;
				}
			}
			input{
				opacity: 0;
				position: absolute;
				display: block;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				cursor: pointer;
			}
			svg{
				width: 25px;
				height: 25px;
			}
		}
	}
</style>
